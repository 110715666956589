import { EnvironmentName } from 'environments';

export interface Flippers {
  readonly showAtlasGlobalNav: boolean;
  readonly newNavBarApi: boolean;
  readonly isBoardsCustomer: boolean;
  readonly showLicensesLink: boolean;
  readonly showSecuritySettings: boolean;
  readonly showNotificationSettings: boolean;
  readonly showNotificationPreferences: boolean;
}

const defaultFlippers: Flippers = {
  showAtlasGlobalNav: false,
  newNavBarApi: false,
  isBoardsCustomer: false,
  showLicensesLink: false,
  showSecuritySettings: false,
  showNotificationSettings: false,
  showNotificationPreferences: false,
};

const dev: Flippers = {
  ...defaultFlippers,
  showAtlasGlobalNav: true,
  newNavBarApi: true,
  isBoardsCustomer: true,
  showLicensesLink: true,
  showSecuritySettings: true,
  showNotificationSettings: true,
  showNotificationPreferences: true,
};

const staging: Flippers = {
  ...defaultFlippers,
  showAtlasGlobalNav: true,
  newNavBarApi: true,
  isBoardsCustomer: true,
  showLicensesLink: true,
  showSecuritySettings: true,
  showNotificationSettings: true,
  showNotificationPreferences: true,
};

const preprod: Flippers = {
  ...defaultFlippers,
  showAtlasGlobalNav: true,
  newNavBarApi: true,
  isBoardsCustomer: true,
  showLicensesLink: true,
  showSecuritySettings: true,
};

const production: Flippers = {
  ...defaultFlippers,
  showAtlasGlobalNav: true,
  newNavBarApi: true,
  isBoardsCustomer: true,
  showLicensesLink: false,
};

const stagingGov: Flippers = {
  ...defaultFlippers,
  showLicensesLink: true,
  newNavBarApi: false,
};

const productionGov: Flippers = {
  ...defaultFlippers,
  newNavBarApi: false,
};

const productionSled: Flippers = {
  ...defaultFlippers,
  newNavBarApi: false,
};

const productionDod: Flippers = {
  ...defaultFlippers,
  newNavBarApi: false,
};

export const envFlippers: Record<EnvironmentName, Flippers> = {
  dev,
  staging,
  preprod,
  production,
  'staging-gov': stagingGov,
  'staging-dod': stagingGov, // Using the same flippers as staging-gov
  'production-gov': productionGov,
  'production-sled': productionSled,
  'production-dod': productionDod,
};
