export type EnvironmentName =
  | 'dev'
  | 'staging'
  | 'preprod'
  | 'production'
  | 'staging-gov'
  | 'staging-dod'
  | 'production-gov'
  | 'production-sled'
  | 'production-dod';

export const getEnvironmentName = (rootDomain): EnvironmentName => {
  switch (rootDomain) {
    case 'highbond-s3.com':
    case 'aclgrc-s3.com':
    case 'diligentoneplatform-dev.com':
      return 'dev';
    case 'highbond-s2.com':
    case 'aclgrc-s2.com':
    case 'diligentoneplatform-preprod.com':
      return 'preprod';
    case 'highbond-s1.com':
    case 'aclgrc-s1.com':
    case 'diligentoneplatform-staging.com':
      return 'staging';
    case 'highbond.com':
    case 'aclgrc.com':
    case 'diligentoneplatform.com':
      return 'production';
    case 'highbond-gov-s1.com':
    case 'diligentoneplatform-staging-gov.com':
      return 'staging-gov';
    case 'highbond-mil-s1.com':
    case 'diligentoneplatform-staging-mil.com':
      return 'staging-dod';
    case 'highbond-gov.com':
    case 'diligentoneplatform-gov.com':
      return 'production-gov';
    case 'highbond-gov2.com':
    case 'diligentoneplatform-gov2.com':
      return 'production-sled';
    case 'highbond-gov3.com':
    case 'highbond.mil':
    case 'diligentoneplatform.mil':
    case 'diligentoneplatform-gov3.com':
      return 'production-dod';
    default:
      throw new Error('Invalid environment!');
  }
};

export const isDiligentOneEnvironment = (rootDomain: string): boolean => {
  // Check if the root domain is a diligentoneplatform domain
  return rootDomain.includes('diligentoneplatform');
};
