import '../setupPublicPath';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { EmotionCache } from '@emotion/utils';
import appConfig from './config';
import { GlobalNavDisplayMode } from '@diligentcorp/atlas-web-component-global-nav/lib/configuration/types';
import { SubdomainSession } from '@acl-services/jwt-highbond-middleware';

export interface PrivateAttributes {
  appOrigin: string;
  appId: string | null;
  helpId: string | null;
  displayMode: GlobalNavDisplayMode;
  excludeSessionAndGlobalNavApiCalls?: boolean;
  appData?: { [key: string]: any };
  sessionData?: SubdomainSession;
  enableAppNav?: boolean | undefined;
}
export interface StaticAttributes extends PrivateAttributes {
  isPublic?: boolean | null;
  orgScope?: string | null;
}

class GlobalNavigatorElement extends HTMLElement {
  static tagName = 'hb-global-navigator';
  private readonly mountRoot: HTMLElement;
  private readonly emotionRoot: HTMLElement;
  private cache: EmotionCache | null = null;
  private shadow = this.attachShadow({ mode: 'open' });
  private isMounted = false;
  private staticAttributes?: StaticAttributes;
  private showAtlas = false;
  private root;

  constructor() {
    super();
    this.mountRoot = document.createElement('div');
    this.emotionRoot = document.createElement('style');
    this.mountRoot.setAttribute('id', 'global-navigator-body');
  }

  get appData() {
    return this.staticAttributes?.appData;
  }

  set appData(value) {
    this.staticAttributes!.appData = value;
    this.render();
  }

  get sessionData() {
    return this.staticAttributes?.sessionData;
  }

  set sessionData(value) {
    this.staticAttributes!.sessionData = value;
    this.render();
  }

  connectedCallback() {
    this.isMounted = true;
    this.staticAttributes = {
      appOrigin: this.getAttribute('app-origin')!,
      appId: this.getAttribute('app-id'),
      helpId: this.getAttribute('help-id'),
      isPublic: this.getAttribute('is-public') === 'true',
      orgScope: this.getAttribute('org-scope') ?? null,
      displayMode: this.getAttribute('display-mode')
        ? (this.getAttribute('display-mode') as GlobalNavDisplayMode)
        : 'dialog',
      excludeSessionAndGlobalNavApiCalls: this.getAttribute('exclude-session-and-global-nav-api-calls') === 'true', // when this attribute set to true, the component will not make any API calls to fetch session and global nav data, so consumers needs to pass these data via pros to appData & sessionData properties
      enableAppNav: this.getAttribute('enable-app-nav') === 'true',
    };

    const {
      flippers: { showAtlasGlobalNav },
    } = appConfig(this.staticAttributes.appOrigin);
    this.showAtlas = showAtlasGlobalNav;

    if (this.showAtlas) {
      this.cache = createCache({
        key: 'css',
        prepend: true,
        container: this.emotionRoot,
      });

      this.shadow.appendChild(this.emotionRoot);
    }
    this.shadow.appendChild(this.mountRoot);
    this.render();
  }

  attributeChangedCallback() {
    this.render();
  }

  disconnectedCallback() {
    this.isMounted = false;
    this.render();
    const root = createRoot(this.shadow);
    setTimeout(() => root.unmount(), 200);
  }

  private render() {
    if (!this.isMounted) return;

    this.root = createRoot(this.mountRoot); // Uses createRoot instead of ReactDOM.render
    if (this.showAtlas) {
      import('./AtlasGlobalNavWrapper').then(({ default: AtlasGlobalNavWrapper }) => {
        this.root.render(
          <CacheProvider value={this.cache}>
            <AtlasGlobalNavWrapper container={this.mountRoot} attributes={this.staticAttributes!}>
              <slot></slot>
            </AtlasGlobalNavWrapper>
          </CacheProvider>,
        );
      });
    } else {
      import('./SrirachaGlobalNavWrapper').then(({ default: SrirachaGlobalNavWrapper }) => {
        this.root.render(
          <StyleSheetManager target={this.shadow}>
            <SrirachaGlobalNavWrapper attributes={this.staticAttributes!} />
          </StyleSheetManager>,
        );
      });
    }
  }
}

if (!window.customElements.get(GlobalNavigatorElement.tagName)) {
  window.customElements.define(GlobalNavigatorElement.tagName, GlobalNavigatorElement);
}
